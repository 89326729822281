import { graphql } from "gatsby"
import { useContext, useState } from "react"
import ThemeContext from "../../context/ThemeContext"
import Layout from "../../components/layout"
import React from "react"
import styles from "./styles.module.scss"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Tab, Tabs, Typography } from "@material-ui/core"
import ContentWrapper from "../../components/text-helpers/content-wrapper"
import SEO from "../../components/seo"
import CasinoHelper from "../../services/casino-bonus-helpers"
import RichText from "../../components/text-helpers/rich-text"
import TableOfContent from "../../components/table-of-content/table-of-content"
import MainSearch from "../../components/main-search/main-search"
import t from "../../services/GetTranslationValue"
import ShowMoreText from "react-show-more-text"
import localize from "../../components/localize"
import { localizeItem } from "../../graphql-assistant/createLocaleTextGetter"
import BlockContent from "../../components/text-helpers/portable-text"

const ContentSection = ({ locale, casinos, title, text }) => {
  return (
    [<p>{text.combinedBody && documentToReactComponents(text.combinedBody.json)}</p>,
      <MainSearch forceTopBonus={true} locale={locale} bonuses={casinos} />,]
  )
}

const Content = ({ locale, currentText, previousText, beforeThatText,biggestBonuses, twoHundredBonuses, oneHundredBonuses,oneFiftyBonuses, fiftyBonuses, fiftyBonusText, oneFiftyText }) =>     {
  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`horizontal-tabpanel-${index}`}
        aria-labelledby={`horizontal-tab-${index}`}
        {...other}
      >
        {children}
      </Typography>
    )
  }

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function TabsIndex(index) {
    return {
      id: `horizontal-tab-${index}`,
      "aria-controls": `horizontal-tabpanel-${index}`,
    }
  }

  const {translations} = useContext(ThemeContext);

  return (
    <div className={`${styles.bonusPageTabsContainer}`}>
      {currentText.footerText && <div className={`${styles.tocParent}`}>
        <TableOfContent pageContext={currentText.footerText.json} />
      </div>}
      <ContentWrapper header={currentText._rawHeaderText} useFullBody={true} headerClassName={`gambleGeneralWrapper`} bodyClassName={`gambleGeneralWrapper`}>
      </ContentWrapper>
      <div className={`${styles.showMoreContainer}`}>
      </div>
      <Tabs
        orientation="horizontal"
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        className={`new-casinos-tabs ${styles.tabs}`}
      >
        <Tab
          label="500%"
          {...TabsIndex(0)}
          className={`${styles.tabTitle}`}
        />
        <Tab
          label="200%"
          {...TabsIndex(1)}
          className={`${styles.tabTitle}`}
        />
        <Tab
          label="150%"
          {...TabsIndex(2)}
          className={`${styles.tabTitle}`}
        />
        <Tab
          label="100%"
          {...TabsIndex(3)}
          className={`${styles.tabTitle}`}
        />

        <Tab
          label="50%"
          {...TabsIndex(3)}
          className={`${styles.tabTitle}`}
        />
      </Tabs>

      <TabPanel
        value={value}
        index={0}
        className={`${styles.tabContent}`}
      >
        <div className={`${styles.tabBody}`}>
          <ContentSection locale={locale} casinos={biggestBonuses} title={"Biggest"} text={currentText} />
        </div>
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        className={`${styles.tabContent}`}
      >
        <div className={`${styles.tabBody}`}>
          <ContentSection locale={locale} casinos={twoHundredBonuses} title={"200"} text={previousText} />
        </div>
      </TabPanel>
      <TabPanel
        value={value}
        index={2}
        className={`${styles.tabContent}`}
      >
        <div className={`${styles.tabBody}`}>
          <ContentSection locale={locale} casinos={oneFiftyBonuses} title={"150"} text={oneFiftyText} />
        </div>
      </TabPanel>
      <TabPanel
        value={value}
        index={3}
        className={`${styles.tabContent}`}
      >
        <div className={`${styles.tabBody}`}>
          <ContentSection locale={locale} casinos={oneHundredBonuses} title={"100"} text={beforeThatText} />
        </div>
      </TabPanel>

      <TabPanel
        value={value}
        index={4}
        className={`${styles.tabContent}`}
      >
        <div className={`${styles.tabBody}`}>
          <ContentSection locale={locale} casinos={fiftyBonuses} title={"50"} text={fiftyBonusText} />
        </div>
      </TabPanel>
      <div className={`${styles.bigBonusText}`}>{currentText._rawFooterText && <BlockContent blocks={currentText._rawFooterText} />}</div>
    </div>
  )
}

const DepositBonusesPage = ({ data, pageContext, location }) => {
  let { translations, bonuses } = useContext(ThemeContext)

  const { locale, alternateLocaleSlugs } = pageContext
  let { currentText, previousText, beforeThatText, fiftyText, oneFiftyText} = data
  let [currentTextNode] = currentText.edges
  let [beforeThatTextNode] = beforeThatText.edges
  let [previousTextNode] = previousText.edges
  let [fiftyTextNode] = fiftyText.edges;
  let [oneFiftyTextNode] = oneFiftyText.edges;

  const biggestBonuses = bonuses
    .filter(x => x.bonuses &&
      CasinoHelper.GetTopFirstDepositBonus(x) &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue >= 200)
    .slice()
    .sort((a,b) => {
      let leftBonus =   CasinoHelper.GetFirstDepositBonus(a);
      let rightBonus = CasinoHelper.GetFirstDepositBonus(b);
      if (!leftBonus || !rightBonus)
        return 0;
      if (rightBonus.bonusValue  > leftBonus.bonusValue)
        return 1;
      if (rightBonus.bonusValue < leftBonus.bonusValue)
        return -1;
      return 0;
    });

  const twoHundredBonuses = bonuses
    .filter(x => x.bonuses &&
      CasinoHelper.GetTopFirstDepositBonus(x) &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue <= 200 &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue > 150)
    .slice()
    .sort((a,b) => {
      let leftBonus = CasinoHelper.GetFirstDepositBonus(a);
      let rightBonus = CasinoHelper.GetFirstDepositBonus(b);
      if (!leftBonus || !rightBonus)
        return 0;
      if (rightBonus.bonusValue  > leftBonus.bonusValue)
        return 1;
      if (rightBonus.bonusValue < leftBonus.bonusValue)
        return -1;
      return 0;
    });

  const oneFiftyBonuses = bonuses
    .filter(x => x.bonuses &&
      CasinoHelper.GetTopFirstDepositBonus(x) &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue <= 150 &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue > 100)
    .slice()
    .sort((a,b) => {
      let leftBonus =   CasinoHelper.GetFirstDepositBonus(a);
      let rightBonus = CasinoHelper.GetFirstDepositBonus(b);
      if (!leftBonus || !rightBonus)
        return 0;
      if (rightBonus.bonusValue  > leftBonus.bonusValue)
        return 1;
      if (rightBonus.bonusValue < leftBonus.bonusValue)
        return -1;
      return 0;
    });

  const oneHundredBonuses = bonuses
    .filter(x => x.bonuses &&
      CasinoHelper.GetTopFirstDepositBonus(x) &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue === 100)
    .slice()
    .sort((a,b) => {
      let leftBonus =   CasinoHelper.GetFirstDepositBonus(a);
      let rightBonus = CasinoHelper.GetFirstDepositBonus(b);
      if (!leftBonus || !rightBonus)
        return 0;
      if (rightBonus.bonusValue  > leftBonus.bonusValue)
        return 1;
      if (rightBonus.bonusValue < leftBonus.bonusValue)
        return -1;
      return 0;
    });


  const fiftyBonuses = bonuses
    .filter(x => x.bonuses &&
      CasinoHelper.GetTopFirstDepositBonus(x) &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue === 50)
    .slice()
    .sort((a,b) => {
      let leftBonus =   CasinoHelper.GetFirstDepositBonus(a);
      let rightBonus = CasinoHelper.GetFirstDepositBonus(b);
      if (!leftBonus || !rightBonus)
        return 0;
      if (rightBonus.bonusValue  > leftBonus.bonusValue)
        return 1;
      if (rightBonus.bonusValue < leftBonus.bonusValue)
        return -1;
      return 0;
    });

  return (
    <Layout breadcrumbNameOverride={currentTextNode &&
    currentTextNode.node &&
    currentTextNode.node.breadcrumbName} silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location} alternateSlug={alternateLocaleSlugs}
           title={
             currentTextNode &&
             currentTextNode.node &&
             currentTextNode.node.seoTitle
           }
           description={
             previousTextNode &&
             previousTextNode.node &&
             previousTextNode.node.seoMeta
           }
      />

      {currentTextNode &&
      currentTextNode.node &&
      currentTextNode.node.heroImage && (
        <Img fluid={currentTextNode.node.heroImage.fluid} />
      )}


      {currentTextNode &&
      currentTextNode.node &&
      currentTextNode.node.h1Tag && (
        <h1 className={`${styles.bonusPageMainTitle}`}> {currentTextNode.node.h1Tag} </h1>
      )}

      {currentText &&
      previousText &&
      beforeThatText &&
      currentTextNode &&
      previousTextNode &&
      fiftyTextNode &&
      oneFiftyTextNode &&
      beforeThatTextNode &&
      currentTextNode.node &&
      previousTextNode.node &&
      beforeThatTextNode.node &&
      fiftyTextNode.node &&
      oneFiftyTextNode.node && (
        <Content
          currentText={currentTextNode.node}
          beforeThatText={beforeThatTextNode.node}
          previousText={previousTextNode.node}
          fiftyBonusText={fiftyTextNode.node}
          oneFiftyText={oneFiftyTextNode.node}
          translations={translations}
          locale={locale}
          biggestBonuses={biggestBonuses}
          twoHundredBonuses={twoHundredBonuses}
          oneHundredBonuses={oneHundredBonuses}
          oneFiftyBonuses={oneFiftyBonuses}
          fiftyBonuses={fiftyBonuses}
        />
      )}
    </Layout>
  )
}

export default localize(DepositBonusesPage)
export const pageQuery = graphql`
query DepositBonusesPageQuery {
    sanitySilo (_id: {eq: "3aacbadb-2f40-40ba-bcb8-f6deeb25edb4"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    currentText: allSanityGenericLandingPage(
      filter: { name: { eq: "DepositBonuses" } }
    ) {
      edges {
        node {
          ...GenericLanding
        }
      }
    }

    previousText: allSanityGenericLandingPage(
      filter: { name: { eq: "200DepositBonus" } }
    ) {
      edges {
        node {
          ...GenericLanding
        }
      }
    }

    beforeThatText: allSanityGenericLandingPage(
      filter: { name: { eq: "100DepositBonus" } }
    ) {
      edges {
        node {
          ...GenericLanding
        }
      }
    }

    oneFiftyText: allSanityGenericLandingPage(
      filter: { name: { eq: "150DepositBonus" } }
    ) {
      edges {
        node {
          ...GenericLanding
        }
      }
    }

    fiftyText: allSanityGenericLandingPage(
      filter: { name: { eq: "50DepositBonus" } }
    ) {
      edges {
        node {
          ...GenericLanding
        }
      }
    }
  }
`
